import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const router = new Router({
    mode: 'history',
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    },
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import('../layout/teller/Teller.vue'),
            // ======================
            // Theme routes / pages
            // ======================
            children: [
            { path: '/', redirect: '/waitinglist' },
            {
                path: '/auth/:key/:token',
                meta:{
                    authRequired: false,
                    authorize: ['*']
                },
                component: () => import('../views/authentication/Login.vue')
            },
            {
                path: '/login',
                name: 'TellerLogin',
                meta:{
                    authRequired: false,
                    authorize: ['*']
                },
                index: 1000,
                component: () => import('../views/authentication/Login.vue')
            },
            {
                path: '/login/norecaptcha',
                name: 'TellerLogin',
                meta:{
                    authRequired: false,
                    authorize: ['*']
                },
                index: 1001,
                component: () => import('../views/authentication/LoginNoCaptcha.vue')
            },
            {
                path: '/waitinglist',
                name: 'WaitingList',
                index: 8,
                meta: {
                    authRequired: false,
                    authorize: ['*'],
                    breadcrumb: [
                    {
                        title: 'WaitingList',
                        active: true
                    },
                    ],
                },
                component: () => import('../views/waitinglist/Index.vue')
            },
            {
                path: '/waitinglist/:id',
                name: 'SingleWaitingList',
                index: 9,
                meta: {
                    authRequired: false,
                    authorize: ['*'],
                    breadcrumb: [
                    {
                        title: 'SingleWaitingList',
                        active: true
                    },
                    ],
                },
                component: () => import('../views/waitinglist/Index.vue')
            },

            {
                path: '/manager_waitinglist',
                name: 'WaitingListManager',
                index: 10,
                meta: {
                    authRequired: false,
                    authorize: ['*'],
                    breadcrumb: [
                    {
                        title: 'WaitingList2',
                        active: true
                    },
                    ],
                },
                component: () => import('../views/waitinglist/Manager.vue')
            },
            {
                path: '/manager_waitinglist/:id',
                name: 'SingleWaitingListManager',
                index: 11,
                meta: {
                    authRequired: false,
                    authorize: ['*'],
                    breadcrumb: [
                    {
                        title: 'SingleWaitingList2',
                        active: true
                    },
                    ],
                },
                component: () => import('../views/waitinglist/Manager.vue')
            },
            {
                path: '/host',
                name: 'Host',
                index: 12,
                meta: {
                    authRequired: true,
                    authorize: ['*'],
                    breadcrumb: [
                    {
                        title: 'Host',
                        active: true
                    },
                    ],
                },
                component: () => import('../components/EditHost/EditHost')
            },
            {
                path: "/maintenance",
                name: "Maintenance",
                index: 8,
                meta: {
                  authRequired: true,
                  authorize: ["*"],
                  breadcrumb: [
                    {
                      title: "Maintenance",
                      active: true,
                    },
                  ],
                },
                component: () => import("../components/Maintenance/Index"),
              },
          ]
        },
       


        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/Error404'
        }
    ]
})


import NProgress from 'nprogress';

router.beforeEach((to, from, next) => {

    const userLoggedIn = JSON.parse(localStorage.getItem('user'));
    if (to.meta.authRequired && !userLoggedIn) {
        localStorage.setItem('user', null);
        return next('/login');
    }

    if (to.name == "Host") {
        if(!process.env.VUE_APP_EDIT_HOST || process.env.VUE_APP_EDIT_HOST == "false") {
            return next('/waitinglist');
        }
    }

    next();
})

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }
    next()
})

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})

export default router