import Vue from 'vue'
import App from './App.vue'
//Bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons';ApiConfigs
import 'bootstrap-icons/font/bootstrap-icons.css'


// Vuex Store
import { store } from './_store';

//Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faCaretLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

import { faSync } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCaretLeft);
library.add(faArrowLeft);
library.add(faCaretRight);
library.add(faSync);
library.add(faArrowAltCircleRight);
library.add(faTimesCircle);
library.add(faCog);
library.add(faSpinner);
library.add(faTimes);
library.add(faBell);
library.add(faPhone);
library.add(faEnvelope);
library.add(faClock);
library.add(faCheck);
library.add(faVolumeUp);
library.add(faChevronDown);
// i18n
import i18n from './i18n/i18n.js'
if(!localStorage.getItem('Language')) { localStorage.setItem('Language', 'fr') }
// Vue Router
import Promise from 'es6-promise';
if (!('Promise' in window)) {
  window.Promise = Promise;
} else if (!('finally' in window.Promise.prototype)) {
  window.Promise.prototype.finally = Promise.prototype.finally;
}
import { router, ApiConfigs, authHeader } from './_helpers';

Vue.config.productionTip = false

require ('./assets/css/style.scss');
require ('./assets/css/vue-multiselect.min.css');


import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

import CountryFlag from 'vue-country-flag'

Vue.component('country-flag', CountryFlag)

// Moment
import moment from 'moment';
import 'moment-timezone';
moment.locale(i18n.locale == 'ar' ? 'ar-tn' : i18n.locale);
Vue.prototype.$moment = moment;

if(i18n.locale == "ar"){
  import('@/assets/css/ar.scss').then(() => {
    console.log("Arabic display");
  })
}

localStorage.setItem("originalHost", process.env.VUE_APP_API_URL_TN);
localStorage.setItem("originalWs", process.env.VUE_APP_WSS_URL);
// ------------------
// import Pusher from 'pusher-js' // import Pusher
 
// Vue.prototype.$pusher = new Pusher(process.env.VUE_APP_PUSHER_ID, {
//   cluster: 'eu',
//   encrypted: true,
//   authEndpoint: ApiConfigs.broadcast_url+ApiConfigs.pusher.auth,
//   auth: {
//       headers: { ...authHeader() }
//   }
// });

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

Vue.prototype.$pusher = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    forceTLS: false,
    wsHost: (process.env.VUE_APP_EDIT_HOST === "true" && localStorage.getItem('host') != null) ? localStorage.getItem('ws') : process.env.VUE_APP_WSS_URL,
    wsPort: 6001,
    disableStats: false,
    enabledTransports: ['ws', 'wss'],
    authEndpoint: ApiConfigs.broadcast_url + ApiConfigs.pusher.auth,
    auth: {
      headers: { ...authHeader() }
    },
});

Vue.prototype.$siteNewVersion = process.env.VUE_APP_SITE_NEW_VERSION;