import { authHeader, ApiConfigs } from '../_helpers';

export const userService = {
    login,
    loginNoCaptcha,
    logout,
    register,
    getAll,
    checkEmail,
    getById,
    update,
    updatePassword,
    requestPasswordReset,
    passwordReset,
    delete: _delete,
    refresh,
    getCaptcha
};

function login(email, password, key, captcha) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, key, captcha })
    };


    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.login}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function loginNoCaptcha(email, password) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };


    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.loginOld}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.logout}`, requestOptions)
        .then(handleResponse)
        .then(function () {
            localStorage.removeItem('user');
            localStorage.removeItem('targetCounter');
        });

}

function register(user) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: {  },
        body: user
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.register}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url}/users`, requestOptions).then(handleResponse);
}


function checkEmail(email) {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.check_email.replace(':email', email)}`, requestOptions).then(handleResponse);
}


function getById(id) {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url}/users/${id}`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { ...authHeader() },
        body: user
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.update}`, requestOptions).then(handleResponse);
}


function updatePassword(password) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { ...authHeader(),  'Content-Type': 'application/json' },
        body: JSON.stringify(password)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.update_password}`, requestOptions).then(handleResponse);
}

function requestPasswordReset(password) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { ...authHeader(),  'Content-Type': 'application/json' },
        body: JSON.stringify(password)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.reset_password}`, requestOptions).then(handleResponse);
}

function passwordReset(password) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { ...authHeader(),  'Content-Type': 'application/json' },
        body: JSON.stringify(password)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.reset_update_password}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        credentials : 'include',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url}/users/${id}`, requestOptions).then(handleResponse);
}

// get latest data in storage
function refresh() {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.refresh}`, requestOptions).then(handleResponse).then(user => {
        let localUser = JSON.parse(localStorage.getItem('user'));
        localUser.business = user.business;
        localUser.permissions = user.permissions;
        localUser.working_days = user.working_days;
        localStorage.setItem('user', JSON.stringify(localUser));
    });
}

function getCaptcha() {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.captcha_url+ApiConfigs.accounts.getCaptcha}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                if(localStorage.getItem('user')){
                    logout();
                }
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}